import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useRouter } from 'next/router';

/**
 * Constants
 */
import { getSpacing } from '@/src/utils/styles/fenixHelpers';

import OtherLoginMethods from '../OtherLoginMethods';
import LoginButtonGroup from '../LoginButtonGroup';

import {
  LOGIN_PAGE_TYPE,
  SOCIAL_NETWORK,
} from '../../components/SocialLogin/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: ${getSpacing(6)};
  }
  > *:last-child {
    margin-bottom: ${getSpacing(12)};
  }
`;

const noop = () => {};

const LoginMethods = ({
  origin,
  trackOrigin,
  onLoginMethodClick = noop,
  loginButtonsConfig,
}) => {
  const socialButtons = loginButtonsConfig.primaryButtons.sort(
    (a, b) => a.priority - b.priority,
  );
  const otherButtons = loginButtonsConfig.secondaryButtons.sort(
    (a, b) => a.priority - b.priority,
  );

  const FEATURED = `FEATURED-${socialButtons.map((o) => o.method).join('-')}`;
  const OTHER = `OTHER-${otherButtons.map((o) => o.method).join('-')}`;
  const trackData = {
    irlConfiguration: `${FEATURED}-${OTHER}`,
    origin: trackOrigin,
  };

  const router = useRouter();
  const { loginType } = router.query;
  const isGoogleLoginType = loginType === SOCIAL_NETWORK.GOOGLE;

  return (
    <Container>
      <LoginButtonGroup
        buttonsConfiguration={socialButtons.filter(({ method }) =>
          isGoogleLoginType
            ? method.toLocaleLowerCase() === SOCIAL_NETWORK.GOOGLE
            : true,
        )}
        cameFromSideMenu
        onLoginMethodClick={onLoginMethodClick}
        origin={origin}
        trackData={trackData}
        isFeatureButton
      />

      {!isGoogleLoginType && (
        <OtherLoginMethods
          buttonsConfiguration={otherButtons}
          cameFromSideMenu
          onLoginMethodClick={onLoginMethodClick}
          origin={origin}
          trackData={trackData}
        />
      )}
    </Container>
  );
};

LoginMethods.propTypes = {
  origin: PropTypes.oneOf(Object.values(LOGIN_PAGE_TYPE)).isRequired,
  trackOrigin: PropTypes.string.isRequired,
  onLoginMethodClick: PropTypes.func.isRequired,
  loginButtonsConfig: PropTypes.object.isRequired,
};

export default LoginMethods;
