import { track } from '../index';
import { LOGIN_SIGNUP_LOADED } from '../constants';

export const TYPE = {
  AUTOMATICALLY: 'automatically',
  MANUALLY: 'manually',
};

export const LAYOUT = {
  IRL: 'irl_configurable',
  FACEBOOK_GOOGLE: 'facebook_google_login',
  SOCIAL: 'social_login',
};

function trackLoginSignUpLoaded({ loadingType, layout, origin }) {
  const EVENT_NAME = LOGIN_SIGNUP_LOADED;

  track(EVENT_NAME, {
    loadingType,
    layout,
    origin,
  });
}

export default trackLoginSignUpLoaded;
