import { useMutation } from 'react-query';

/**
 * Services
 */
import getPhoneValidationUrlExternal from '@/src/services/getPhoneValidationUrlExternal';

export function usePhoneValidationExternal() {
  return useMutation(getPhoneValidationUrlExternal);
}
