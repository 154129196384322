/**
 * Check if the current param is an string
 * @returns boolean
 */
function isString(object) {
  return (
    object === '' ||
    (Boolean(object) &&
      (typeof object === 'string' || object instanceof String))
  );
}

export default isString;
