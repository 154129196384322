import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

/**
 * Components
 */
import Overlay from '@/src/components/BottomSheet/components/Overlay';
import BottomSheetContent from '@/src/components/BottomSheet/components/BottomSheetContent';
import BottomSheetBody from '@/src/components/BottomSheet/components/BottomSheetBody';
import Handler from '@/src/components/BottomSheet/components/Handler';

/**
 * Hooks
 */
import useOnClickOutside from '@/src/hooks/useOnClickOutside';
import useNotScrollable from '@/src/hooks/useNotScrollable';

const BottomSheet = ({
  children,
  open,
  onClickOut,
  showOverlay,
  className,
  scrollEnabled,
}) => {
  const bottomSheetRef = useRef(null);
  const closeBottomSheet = useCallback(() => {
    onClickOut(false);
  }, [onClickOut]);

  const clickOutside = useCallback(() => {
    if (open) {
      closeBottomSheet();
    }
  }, [closeBottomSheet, open]);

  useOnClickOutside(bottomSheetRef, clickOutside);
  useNotScrollable(bottomSheetRef, scrollEnabled ? false : open);

  return (
    <Overlay
      data-testid="overlay-div"
      toggle={open}
      showOverlay={showOverlay}
      className={className}
    >
      <BottomSheetContent ref={bottomSheetRef}>
        <Handler />
        <BottomSheetBody>{children}</BottomSheetBody>
      </BottomSheetContent>
    </Overlay>
  );
};

BottomSheet.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClickOut: PropTypes.func,
  showOverlay: PropTypes.bool,
  className: PropTypes.string,
  scrollEnabled: PropTypes.bool,
};

BottomSheet.defaultProps = {
  onClickOut: () => {},
  showOverlay: true,
  scrollEnabled: true,
};

BottomSheet.displayName = 'BottomSheet';

export default BottomSheet;
