import { defineMessages } from 'react-intl';

export default defineMessages({
  text: {
    id: 'app.modules.login.emailButton.text',
    defaultMessage: 'Continuar con correo electrónico',
  },
  buttonText: {
    id: 'app.modules.login.emailButton.buttonText',
    defaultMessage: 'Continuar con email',
  },
});
