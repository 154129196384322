import PropTypes from 'prop-types';
import React, { createContext, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import PrivacyPoliciesAndTermsAndConditions from '../components/PrivacyPoliciesAndTermsAndConditions';

const Container = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex: 1;
  width: 100%;
`;

const PrivacyPoliciesAndTermsAndConditionsContext = createContext({
  accept: () => {},
  hide: () => {},
  isAccepted: false,
  show: () => {},
  setLoginMethod: () => {},
});

const PrivacyPoliciesAndTermsAndConditionsProvider = ({
  accepted = false,
  children,
}) => {
  const onAcceptRef = useRef(null);
  const onBackRef = useRef(null);
  const [isAccepted, setAccepted] = useState(accepted);
  const [shouldShow, setShow] = useState(false);
  const [loginMethod, setLoginMethod] = useState('');

  const accept = () => {
    setAccepted(true);
    setLoginMethod('');

    if (onAcceptRef.current) {
      onAcceptRef.current();
      onAcceptRef.current = null;
    }
  };

  const show = (onAccept, onBack) => {
    setAccepted(false);
    setShow(true);
    onAcceptRef.current = onAccept;
    onBackRef.current = onBack;
  };

  const hide = () => {
    setLoginMethod('');
    setShow(false);

    if (onBackRef.current) {
      onBackRef.current();
      onBackRef.current = null;
    }
  };

  const isRequired = shouldShow && !isAccepted;

  return (
    <PrivacyPoliciesAndTermsAndConditionsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ accept, hide, isAccepted, show, setLoginMethod }}
    >
      {isRequired ? (
        <PrivacyPoliciesAndTermsAndConditions
          onAcceptPress={accept}
          onBackPress={hide}
          loginMethod={loginMethod}
        />
      ) : null}
      <Container show={!isRequired}>{children}</Container>
    </PrivacyPoliciesAndTermsAndConditionsContext.Provider>
  );
};

PrivacyPoliciesAndTermsAndConditionsProvider.propTypes = {
  accepted: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

const usePrivacyPoliciesAndTermsAndConditions = () => {
  const context = useContext(PrivacyPoliciesAndTermsAndConditionsContext);
  if (context === undefined) {
    throw new Error(
      'usePrivacyPoliciesAndTermsAndConditions must be used within a PrivacyPoliciesAndTermsAndConditionsProvider',
    );
  }

  return context;
};

export {
  PrivacyPoliciesAndTermsAndConditionsProvider,
  usePrivacyPoliciesAndTermsAndConditions,
};
