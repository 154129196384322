import { track } from '../index';
import { LOGIN_SIGNUP_CLICKED } from '../constants';

export const LOCATION = {
  OMIT: 'omit',
  OTHER: 'other',
  PHONE: 'phone',
  FORGOT_PASSWORD: 'forgot_password',
  TERMS: 'terms',
  PRIVACY: 'privacy',
};

const trackLoginSignUpClicked = ({ clickLocation, origin }) => {
  const EVENT_NAME = LOGIN_SIGNUP_CLICKED;

  track(EVENT_NAME, {
    clickLocation,
    origin,
  });
};

export default trackLoginSignUpClicked;
