import React from 'react';
import PropTypes from 'prop-types';
import stateMapper from '@/src/models/stateMapper';
import WebScripts from '@/src/scripts';
import View from '@/src/views/login-flow';
import {
  FWFService,
  SessionService,
  TrackingService,
} from '@/src/server/services';
import { getAppVersion, getPlatform, getLocale } from '@/src/server/utils';

/**
 * Utils
 */
import createContext from '@/src/utils/fwf/createContext';
import { FLAGS_KEYS } from '@/external-libs/fwf/constants';
import { getConsumer } from '../server/controllers/auth/utils';

export default function Home({ fwfConfig, trackerConfig }) {
  return (
    <>
      <WebScripts fwfConfig={fwfConfig} trackerConfig={trackerConfig} />
      <View />
    </>
  );
}

Home.propTypes = {
  fwfConfig: PropTypes.shape({
    config: PropTypes.shape({}),
    context: PropTypes.shape({}),
  }),
  trackerConfig: PropTypes.shape({
    historicalEnabled: PropTypes.bool,
    gtmConfig: PropTypes.object,
    commonData: PropTypes.object,
  }),
};

const getMessages = async (locale = 'es') => {
  const response = await import(`../translations/${locale}.js`);
  return response ? response.default : {};
};

export async function getServerSideProps({ req, query }) {
  const { token, loginFrom, ...queryParams } = query;
  // From Session
  const country = SessionService.getCountry(req) || {};
  const user = SessionService.getUser(req);
  const headersPedidosYa = SessionService.getPedidosYaHeaders(req);
  const sessionId = SessionService.getSessionId(req);
  // From Request
  const currentVersion = getAppVersion(req);
  const currentPlatform = getPlatform(req);
  // Default values
  const defaultPlatform = 'mweb';

  const locale = getLocale(country);
  const messages = await getMessages(locale);

  const consumer = getConsumer({ consumerKey: loginFrom });

  // FWF Implementation
  const context = createContext({
    country,
    user,
    googleClientId: sessionId,
    version: currentVersion,
    platform: defaultPlatform,
  });

  const flags = await FWFService.getFlags({
    context,
    keys: FLAGS_KEYS,
  });

  const initialState = stateMapper({
    appVersion: currentVersion,
    platform: currentPlatform,
    user,
    country,
    searchQueryParams: queryParams,
    consumer,
    flags,
    sessionId,
  });

  const trackerConfig = TrackingService.getTrackerConfig(req);

  return {
    props: {
      initialState,
      trackerConfig,
      fwfConfig: {
        platform: currentPlatform,
        config: {
          appVersion: currentVersion,
        },
        context: {
          email: user?.email || '',
          userId: user?.id || '',
          country: country?.shortName || '',
        },
      },
      i18n: {
        messages,
        locale,
      },
      axios: {
        headers: headersPedidosYa,
      },
    },
  };
}
