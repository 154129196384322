import getInstance from '@/external-libs/axios';

/**
 * Utils
 */
import { loginWithPhoneTokenURL } from '@/src/constants/urls/apiURLs';
import ServiceResponse from '@/src/models/ServiceResponse';

async function loginWithPhoneToken(payload) {
  try {
    const axios = getInstance();
    const { data } = await axios.post(loginWithPhoneTokenURL, payload);

    return new ServiceResponse({ data: data.data });
  } catch (error) {
    return new ServiceResponse({ error });
  }
}

export default loginWithPhoneToken;
