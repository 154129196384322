import React from 'react';
import PropTypes from 'prop-types';

import { withRouter, useRouter } from 'next/router';
import NextLink from 'next/link';

import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';

/**
 * Providers
 */
import { useConsumer } from '@/src/providers/ConsumerProvider';
import { useCountryShortName } from '@/src/providers/CountryProvider';

/**
 * Components
 */
import Button from '@pedidosya/web-fenix/atoms/Button';
import Container from '@/src/components/Container';
import Content from '@/src/components/Content';
import GoBackHeader from '@/src/components/GoBackHeader';
import ShellFooter from '@/src/components/ShellFooter';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import GoogleSignInButton from '../../../components/SocialLogin/GoogleSignInButton';

/**
 * Utils
 */
import getFaqLinks from './utils/getFaqLinks';
import showCountryDisclaimer from './utils/showCountryDisclaimer';
import { getSpacing } from '../../../../../utils/styles/fenixHelpers';

/**
 * Constants
 */
import { SOCIAL_NETWORK } from '../../../components/SocialLogin/constants';

/**
 * Assets
 */
import image from '@/src/assets/theme/images/staff-agent-no-results.svg';

import messages from './messages';

const Image = styled.img`
  margin-bottom: 36px;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.textPrimary};
  line-height: 26px;
  text-align: center;
`;

const CountryDisclaimer = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary};
  line-height: 16px;
  margin-top: 8px;
  text-align: center;
`;

const Link = styled(NextLink)`
  color: ${({ theme }) => theme.colors.textPrimary};
  text-decoration: underline;
`;

const Footer = styled(ShellFooter)`
  padding: ${getSpacing(10, 12)};
`;

const RoundedButton = styled(Button)`
  border-radius: 25px;
`;

function PrivacyPoliciesAndTermsAndConditions({
  intl: { formatMessage },
  onAcceptPress,
  onBackPress,
  loginMethod,
}) {
  const router = useRouter();
  const { originPath } = router.query;

  const consumer = useConsumer();
  const countryShortName = useCountryShortName();

  const faqLinks = getFaqLinks({ consumer, countryShortName });
  const isGoogleLoginMethod = loginMethod === SOCIAL_NETWORK.GOOGLE;

  return (
    <>
      <GoBackHeader onBackPress={onBackPress} />
      <Container>
        <Content centered>
          <Image alt="login-imagen" src={image.src} />
          <Text>
            <Typography token="font-body-midcontrast-sentence-large">
              <FormattedMessage
                {...messages.text}
                values={{
                  termsAndConditions: (
                    <Link href={faqLinks.tyc} target="_blank">
                      {formatMessage(messages.termsAndConditions)}
                    </Link>
                  ),
                  privacyPolicies: (
                    <Link href={faqLinks.privacy} target="_blank">
                      {formatMessage(messages.privacyPolicies)}
                    </Link>
                  ),
                }}
              />
            </Typography>
          </Text>
          {showCountryDisclaimer(countryShortName) ? (
            <CountryDisclaimer>
              <Typography token="font-body-midcontrast-sentence-small">
                {formatMessage(messages.countryDisclaimer)}
              </Typography>
            </CountryDisclaimer>
          ) : null}
        </Content>
        <Footer>
          {isGoogleLoginMethod ? (
            <GoogleSignInButton
              loginFrom={consumer.key}
              originPath={originPath}
              onClick={onAcceptPress}
            />
          ) : (
            <RoundedButton
              label={formatMessage(messages.accept)}
              color="primary"
              type="submit"
              onClick={onAcceptPress}
              fullWidth
              size="large"
            />
          )}
        </Footer>
      </Container>
    </>
  );
}

PrivacyPoliciesAndTermsAndConditions.propTypes = {
  onAcceptPress: PropTypes.func.isRequired,
  onBackPress: PropTypes.func.isRequired,
  loginMethod: PropTypes.string,
};

export default withRouter(injectIntl(PrivacyPoliciesAndTermsAndConditions));
