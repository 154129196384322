/**
 * Constants
 */
import { COUNTRY_SHORT_NAME } from '@appscore/web-constants/countries';

const { VENEZUELA, NICARAGUA } = COUNTRY_SHORT_NAME;

export const countriesWithDisclaimer = [VENEZUELA, NICARAGUA];

const showCountryDisclaimer = (country) =>
  countriesWithDisclaimer.some((c) => c === country);

export default showCountryDisclaimer;
