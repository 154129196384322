export const mapCourierConfig = (data) => {
  const transformButton = (button) => ({
    priority: button.order,
    method: button.type,
  });

  return {
    primaryButtons: data.featured_buttons.map(transformButton),
    secondaryButtons: data.other_buttons.map(transformButton),
  };
};

export const isCourierConsumer = (name = '') => /courier/i.test(name);
