export const LOGIN_URL = '/api/auth/login';
export const loginWithPhoneTokenURL = '/api/auth/loginWithPhoneToken';
export const getLoginMethodsURL = '/api/auth/signup/login-methods';
export const getLoginMethodsV2URL = '/api/auth/login-methods';
export const signUpV2URL = '/api/auth/v2/signup';
export const linkAccountURL = '/api/auth/signup/link-account';
export const googleConfigURL = '/api/google-config';
export const phoneValidationURL = '/api/phone-validation-url';
export const LOGIN_EMAIL_OTP = '/api/auth/login/email-otp';
export const VALIDATE_OTP_CODE = '/api/auth/login/validate-otp-code';
export const LINK_ACCOUNT_OTP = '/api/auth/signup/link-account-otp';
