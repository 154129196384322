import styled, { css } from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${({ centered }) =>
    centered &&
    css`
      align-items: center;
      justify-content: center;
    `}

  ${({ hidden }) =>
    hidden &&
    css`
      visibility: hidden;
    `}
`;

export default Content;
