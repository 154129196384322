import { track } from '../index';
import { AUTH_FAILED } from '../constants';
import { SOCIAL_NETWORK } from '@/external-libs/tracker/constants';

export const TYPE = {
  FACEBOOK: SOCIAL_NETWORK.FACEBOOK,
  GOOGLE: SOCIAL_NETWORK.GOOGLE,
};

function trackSocialLoginFailure({ errorMessage, type }) {
  const EVENT_NAME = AUTH_FAILED;

  track(EVENT_NAME, {
    authenticationErrorMessage: errorMessage,
    authenticationType: type,
  });
}
export default trackSocialLoginFailure;
