import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Components
 */
import IconButton from '@pedidosya/web-fenix/atoms/IconButton';
import ChevronLeft from '@pedidosya/web-fenix/icons/ChevronLeft';

/**
 * Utils
 */
import {
  onKeyDownFunction,
  onKeyUpFunction,
} from '@pedidosya/web-fenix/utils/a11y';

const Header = styled.div`
  background-color: ${({ background }) => background};
`;

const BackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 0;
  height: 65px;
  width: 65px;
`;

function GoBackHeader({ background, onBackPress }) {
  return (
    <Header background={background}>
      <BackContainer
        aria-label="volver"
        tabIndex={0}
        onKeyUp={(e) => onKeyUpFunction(e, onBackPress)}
        onKeyDown={(e) => onKeyDownFunction(e, onBackPress)}
        role="button"
      >
        <IconButton
          aria-label="Regresar"
          icon={<ChevronLeft />}
          size="medium"
          withStroke={false}
          onClick={onBackPress}
        />
      </BackContainer>
    </Header>
  );
}

GoBackHeader.propTypes = {
  background: PropTypes.string,
  onBackPress: PropTypes.func.isRequired,
};

GoBackHeader.defaultProps = {
  background: 'none',
};

export default GoBackHeader;
