import { useEffect } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

function useNotScrollable(ref, open) {
  useEffect(() => {
    if (!open) {
      clearAllBodyScrollLocks();
    } else if (ref?.current) {
      disableBodyScroll(ref.current);
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [open, ref]);
}

export default useNotScrollable;
