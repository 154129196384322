import getInstance from '@/external-libs/axios';

/**
 * Utils
 */
import { getLoginMethodsV2URL } from '@/src/constants/urls/apiURLs';
import ServiceResponse from '@/src/models/ServiceResponse';

export default async function getLoginMethodsV2() {
  try {
    const axios = getInstance();
    const { data } = await axios.get(getLoginMethodsV2URL);

    return new ServiceResponse({ ...data });
  } catch (error) {
    return new ServiceResponse({ error });
  }
}
