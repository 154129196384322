import React from 'react';
import PropTypes from 'prop-types';

/**
 * Assets
 */
import Email from '@pedidosya/web-fenix/icons/Email';

/**
 * Components
 */
import BottomSheetItem from '../components/BottomSheetItem';
import {
  SocialButton,
  SocialContainer,
  SocialWrapper,
} from '../components/SocialLoginCommons';

import messages from './messages';

function PhoneButton({
  intl: { formatMessage },
  onClick,
  isBottomSheetButton,
  closeBottomSheet = () => {},
}) {
  const handlePress = () => {
    closeBottomSheet();
    onClick();
  };

  return isBottomSheetButton ? (
    <BottomSheetItem
      elementId="phone_login_btn"
      label={formatMessage(messages.text)}
      onClick={handlePress}
    />
  ) : (
    // check if we are using this component
    <SocialContainer onClick={handlePress}>
      <SocialWrapper>
        <Email style={{ margin: 'auto' }} />
      </SocialWrapper>
      <SocialButton label={formatMessage(messages.buttonText)} />
    </SocialContainer>
  );
}

PhoneButton.defaultProps = {
  isBottomSheetButton: false,
};

PhoneButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  closeBottomSheet: PropTypes.func,
  isBottomSheetButton: PropTypes.bool,
};

export default PhoneButton;
