import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  > * {
    padding: ${({ theme }) => theme.constants.lateralSpace};
  }

  ${({ background }) =>
    background === 'red' &&
    css`
      background-color: ${({ theme }) => theme.colors.red};
      color: ${({ theme }) => theme.colors.white};
    `}
`;

export default Container;
