import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'next/router';

/**
 * Assets
 */
import Phone from '@pedidosya/web-fenix/icons/Phone';

/**
 * Components
 */
import BottomSheetItem from '../components/BottomSheetItem';
import {
  SocialButton,
  SocialContainer,
  SocialWrapper,
} from '../components/SocialLoginCommons';

import messages from './messages';

function EmailButton({
  intl: { formatMessage },
  onClick,
  isBottomSheetButton,
  closeBottomSheet = () => {},
}) {
  const handlePress = () => {
    closeBottomSheet();
    onClick();
  };

  return isBottomSheetButton ? (
    <BottomSheetItem
      label={formatMessage(messages.text)}
      onClick={handlePress}
    />
  ) : (
    <SocialContainer data-testid="social-container-div" onClick={handlePress}>
      <SocialWrapper>
        <Phone style={{ margin: 'auto' }} />
      </SocialWrapper>
      <SocialButton label={formatMessage(messages.buttonText)} />
    </SocialContainer>
  );
}

EmailButton.defaultProps = {
  isBottomSheetButton: false,
};

EmailButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  closeBottomSheet: PropTypes.func,
  isBottomSheetButton: PropTypes.bool,
};

export default injectIntl(withRouter(EmailButton));
