import { track } from '../index';
import {
  SOCIAL_NETWORK,
  MAIL,
  PHONE,
  OTP,
  AUTH_FACEBOOK_CLICKED,
  AUTH_GOOGLE_CLICKED,
  AUTH_MAIL_CLICKED,
} from '../constants';

export const METHOD = {
  FACEBOOK: SOCIAL_NETWORK.FACEBOOK,
  GOOGLE: SOCIAL_NETWORK.GOOGLE,
  MAIL: MAIL,
  PHONE: PHONE,
  OTP: OTP,
};

function getEventName(method) {
  switch (method) {
    case METHOD.FACEBOOK:
      return AUTH_FACEBOOK_CLICKED;
    case METHOD.GOOGLE:
      return AUTH_GOOGLE_CLICKED;
    case METHOD.MAIL:
    case METHOD.OTP:
      return AUTH_MAIL_CLICKED;
    default:
      return `$auth_${method}.clicked`;
  }
}

const trackAuthMethodButtonClick = ({ method, irlConfiguration, origin }) => {
  const EVENT_NAME = getEventName(method);

  track(EVENT_NAME, {
    irlConfiguration,
    origin,
  });
};

export default trackAuthMethodButtonClick;
