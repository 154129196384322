import { LOGIN_ERROR_CODES } from '@/src/constants/ErrorCodes';
import { defineMessages } from 'react-intl';

export default defineMessages({
  and: {
    id: 'app.modules.user.integratedRegisterLogin.and',
    defaultMessage: 'y',
  },
  continueWithOtherMethod: {
    id: 'app.modules.user.integratedRegisterLogin.continueWithOtherMethod',
    defaultMessage: 'Otro método',
  },
  countriesTitle: {
    id: 'app.modules.user.integratedRegisterLogin.countriesTitle',
    defaultMessage: 'Elegir país',
  },
  skip: {
    id: 'app.modules.user.integratedRegisterLogin.skip',
    defaultMessage: 'Ahora no',
  },
  privacyPoliciesLink: {
    id: 'app.modules.user.integratedRegisterLogin.privacyPoliciesLink',
    defaultMessage: 'política de privacidad',
  },
  termsAndConditionsDisclaimer: {
    id: 'app.modules.user.integratedRegisterLogin.termsAndConditionsDisclaimer',
    defaultMessage: 'Al continuar, aceptas nuestros',
  },
  termsAndConditionsLink: {
    id: 'app.modules.user.integratedRegisterLogin.termsAndConditionsLink',
    defaultMessage: 'términos de uso',
  },
  title: {
    id: 'app.modules.login.home.title',
    defaultMessage: '¡Te damos la bienvenida!',
  },
  subtitle: {
    id: 'app.modules.login.home.subtitle',
    defaultMessage: '¿Cómo quieres continuar?',
  },
  titleForPeYaCartOrCheckout: {
    id: 'app.modules.login.home.cart_or_checkout_title',
    defaultMessage: 'Elige cómo continuar con tu pedido',
  },
  sendWhatsApp: {
    id: 'app.modules.user.integratedRegisterLogin.whatsapp',
    defaultMessage: 'Enviar código por Whatsapp',
  },
  sendSMS: {
    id: 'app.modules.user.integratedRegisterLogin.sendSMS',
    defaultMessage: 'Enviar código por SMS',
  },
  titleForLogin: {
    id: 'app.modules.login.home.titleForLogin',
    defaultMessage: 'Elige cómo quieres ingresar',
  },
  acceptInformation: {
    id: 'app.modules.login.home.acceptInformation',
    defaultMessage:
      'Al aceptar también confirmas que darás tu nombre completo como lo indica tu documento de identificación oficial.',
  },
  phoneLoginErrorMessage: {
    id: 'app.modules.login.phoneValidation.enterPhoneNumber.phoneLogin.errorMessage',
    defaultMessage: 'Uy... algo pasó. Vuelve a intentar en unos minutos.',
  },
  [LOGIN_ERROR_CODES.NOT_ALLOWED]: {
    id: 'app.modules.login.home.errorMessage.notAllowed',
    defaultMessage: 'El dominio del mail ingresado no está permitido.',
  },
  [LOGIN_ERROR_CODES.INVALID_DOMAIN]: {
    id: 'app.modules.login.home.errorMessage.invalidDomain',
    defaultMessage: 'El dominio del mail ingresado no está permitido.',
  },
  [LOGIN_ERROR_CODES.USR_FACEBOOK_NO_MAIL]: {
    id: 'app.modules.login.home.errorMessage.userFbNoEmail',
    defaultMessage:
      'No pudimos registrarte con Facebook. Por favor, ingresa con otro método.',
  },
  socialNetworkGenericErrorMessage: {
    id: 'app.modules.login.home.errorMessage.socialNetworkGeneric',
    defaultMessage:
      'Ocurrió un error y no se pudo continuar con {socialNetworkName}.',
  },
});
