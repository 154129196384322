/* eslint-disable react/display-name */
import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

/**
 * Assets
 */
import pedidosYa from '@/src/assets/images/login/pedidosya/logo.svg';

const Container = styled.div`
  opacity: 1;
  overflow-y: hidden;
  transition: opacity 0.33s ease-in-out, transform 0.33s linear;
  ${({ hideLogo }) =>
    hideLogo &&
    css`
      transform: translateY(-50vh);
      opacity: 0;
    `}
`;

const Image = styled.img`
  height: auto;
`;

const Logo = React.memo(({ hideLogo }) => (
  <Container hideLogo={hideLogo}>
    <Image src={pedidosYa.src} alt="PedidosYa" />
  </Container>
));

Logo.propTypes = {
  hideLogo: PropTypes.bool,
};

Logo.defaultProps = {
  hideLogo: false,
};

export default Logo;
