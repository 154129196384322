import { track } from '../index';
import { LOGIN_SUCCEEDED, REGISTER_COMPLETED } from '../constants';

function trackSocialLoginOk({ userId, isNewUser, socialNetwork }) {
  const EVENT_NAME = isNewUser ? REGISTER_COMPLETED : LOGIN_SUCCEEDED;

  track(EVENT_NAME, {
    [isNewUser ? 'registrationType' : 'loginType']: socialNetwork,
    userId,
  });
}
export default trackSocialLoginOk;
