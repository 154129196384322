import styled from 'styled-components';
import { getSpacing } from '@/src/utils/styles/fenixHelpers';

const Handler = styled.span`
  background-color: ${({ theme }) => theme.commons.bottomSheet.handlerColor};
  border-radius: 4px;
  height: 6px;
  left: calc(50% - 32px);
  position: absolute;
  top: ${getSpacing(2)};
  width: 64px;
`;

export default Handler;
