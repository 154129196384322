import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';

/**
 * Tracking
 */
import trackLoginSignUpClicked, {
  LOCATION,
} from '@/external-libs/tracker/events/trackLoginSignUpClicked';

/**
 * Utils
 */
import useIsDesktop from '@/src/hooks/useIsDesktopLayout';

/**
 * Components
 */
import BottomSheet from '@pedidosya/web-fenix/organisms/BottomSheet';
import CustomBottomSheet from '@/src/components/BottomSheet';
import { SocialButton as Button } from '@/src/modules/Login/components/IntegratedRegisterLogin/components/Button/Button';
import { getSpacing } from '../../../../utils/styles/fenixHelpers';
import LoginButtonGroup from '../LoginButtonGroup';

import {
  LOGIN_PAGE_TYPE,
  SIGN_UP_TYPE,
  SOCIAL_NETWORK,
} from '../../components/SocialLogin/constants';

import messages from '../../components/SocialLogin/messages';

const Container = styled.div``;

const BottomSheetWrapper = styled.section`
  padding: ${getSpacing(9, 12, 10, 12)};
`;

const CustomBottomSheetContainer = styled(CustomBottomSheet)`
  position: absolute;
`;

const BottomSheetContainer = styled.div`
  padding: ${getSpacing(10, 12, 12, 12)};
`;

const noop = () => {};

const { FACEBOOK, GOOGLE } = SOCIAL_NETWORK;
const { PASSWORD, PHONE } = SIGN_UP_TYPE;

function OtherLoginMethods({
  buttonsConfiguration,
  origin,
  trackData,
  onLoginMethodClick = noop,
  intl: { formatMessage },
}) {
  const isDesktop = useIsDesktop();
  const [isBottomSheetOpen, setOpenBottomSheet] = useState(false);
  const openBottomSheet = () => {
    trackLoginSignUpClicked({
      clickLocation: LOCATION.OTHER,
      origin: trackData.origin,
    });
    // trackBottomSheetShown({ authenticationOptions: AUTH_OPTIONS });
    setOpenBottomSheet(true);
  };

  const closeBottomSheet = () => {
    setOpenBottomSheet(false);
  };

  if (!buttonsConfiguration || !buttonsConfiguration.length) {
    return null;
  }

  const BottomSheetButtons = (
    <LoginButtonGroup
      buttonsConfiguration={buttonsConfiguration}
      cameFromSideMenu
      onLoginMethodClick={onLoginMethodClick}
      origin={origin}
      trackData={trackData}
      closeBottomSheet={closeBottomSheet}
      isBottomSheetButton
    />
  );

  return (
    <Container>
      <Button
        elementId="login_continue_with_other_method"
        label={formatMessage(messages.continueWithOtherMethod)}
        color="primary"
        onClick={openBottomSheet}
      />

      {isDesktop ? (
        <CustomBottomSheetContainer
          open={isBottomSheetOpen}
          onClickOut={closeBottomSheet}
        >
          <BottomSheetContainer>{BottomSheetButtons}</BottomSheetContainer>
        </CustomBottomSheetContainer>
      ) : (
        <BottomSheet
          handler
          open={isBottomSheetOpen}
          onClickOut={closeBottomSheet}
          title="Otro método"
        >
          <BottomSheetWrapper>{BottomSheetButtons}</BottomSheetWrapper>
        </BottomSheet>
      )}
    </Container>
  );
}

OtherLoginMethods.propTypes = {
  buttonsConfiguration: PropTypes.arrayOf(
    PropTypes.shape({
      method: PropTypes.oneOf([FACEBOOK, GOOGLE, PASSWORD, PHONE]).isRequired,
      priority: PropTypes.number.isRequired,
    }),
  ).isRequired,
  onLoginMethodClick: PropTypes.func.isRequired,
  origin: PropTypes.oneOf(Object.values(LOGIN_PAGE_TYPE)).isRequired,
  trackData: PropTypes.object.isRequired,
};

export default injectIntl(OtherLoginMethods);
