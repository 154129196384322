import {
  PEDIDOS_YA_WEB_STRING_ENCODE,
  PEDIDOS_YA_WEB_DEV_STRING_ENCODE,
  PEDIDOS_YA_WEB_LOCAL_STRING_ENCODE,
} from '@/src/constants/consumers';

export const isPeYaWeb = (consumerKey) =>
  [
    PEDIDOS_YA_WEB_STRING_ENCODE,
    PEDIDOS_YA_WEB_DEV_STRING_ENCODE,
    PEDIDOS_YA_WEB_LOCAL_STRING_ENCODE,
  ].includes(consumerKey);
