import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import messages from './messages';

const HeaderContainer = styled.header`
  display: flex;
  position: relative;
`;

HeaderContainer.Action = styled.div`
  flex-grow: 1;
  line-height: 18px;
  text-align: right;
`;

function Header({ onClick, intl: { formatMessage } }) {
  return (
    <HeaderContainer>
      <HeaderContainer.Action onClick={onClick}>
        <Typography token="font-label-highercontrast-small">
          {formatMessage(messages.skip)}
        </Typography>
      </HeaderContainer.Action>
    </HeaderContainer>
  );
}

Header.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default injectIntl(Header);
