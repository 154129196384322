/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter, useRouter } from 'next/router';
import Link from 'next/link';
import * as queryString from 'query-string';
import styled from 'styled-components';

import { FLAGS } from '@/external-libs/fwf/constants';

/**
 * Utils
 */
import { isPeYaWeb } from '@/src/utils/consumers/index';
import useToast from '@/src/hooks/useToast';
import ROUTES from '@/src/constants/routes';
import openPage from '@/src/utils/browser/openPage';
import {
  getSocialNetworkFromParams,
  removeSocialNetworkParams,
} from '@/src/utils/socialNetwork';
import { capitalize } from '@/src/utils/string/string';
import {
  generateURL,
  redirectToExternalUrl,
  validateUrl,
} from '@/src/utils/url/url';
import { getSpacing } from '@/src/utils/styles/fenixHelpers';
import { mapCourierConfig, isCourierConsumer } from '@/src/utils/courier';

/**
 * Tracking
 */
import { ORIGIN } from '@/external-libs/tracker/constants';
import trackLoginSignUpClicked, {
  LOCATION,
} from '@/external-libs/tracker/events/trackLoginSignUpClicked';
import trackLoginFailed, {
  TYPE,
} from '@/external-libs/tracker/events/trackLoginFailed';
import trackLoginSucceeded from '@/external-libs/tracker/events/trackLoginSucceeded';
import trackLoginSignUpLoaded, {
  TYPE as LOADED_TYPE,
} from '@/external-libs/tracker/events/trackLoginSignUpLoaded';
import trackSocialLoginFailure from '@/external-libs/tracker/events/trackSocialLoginFailure';
import trackSocialLoginOk from '@/external-libs/tracker/events/trackSocialLoginOk';

/**
 * Providers
 */
import { useConsumer } from '@/src/providers/ConsumerProvider';
import { useCountryShortName } from '@/src/providers/CountryProvider';
import { useFlagVariation } from '@/src/providers/FunWithFlagsProvider';

/**
 * Components
 */
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Container from '@/src/components/Container';
import Content from '@/src/components/Content';
import OverlayLoader from '@/src/components/OverlayLoader';
import Footer from '@/src/modules/Login/components/IntegratedRegisterLogin/components/Footer';
import Logo from '@/src/modules/Login/components/IntegratedRegisterLogin/components/Logo';
import LoginMethods from '../../containers/LoginMethods';
import Header from './components/Header';

/**
 * Hooks
 */
import { usePhoneTokenLogin } from './hooks/usePhoneTokenLogin';
import { usePhoneValidationExternal } from './hooks/usePhoneValidationExternal';
import { useLoginMethods } from './hooks/useLoginMethods';

import messages from './messages';

/**
 * Constants
 */
import {
  DEFAULT_CONFIGURATION,
  LOGIN_PAGE_TYPE,
  NON_ASSOCIATED_USER_CODE,
  EMAIL,
  PHONE_TOKEN,
} from './constants';

import showCountryDisclaimer from '../../containers/components/PrivacyPoliciesAndTermsAndConditions/utils/showCountryDisclaimer';
import getFaqLinks from '../../containers/components/PrivacyPoliciesAndTermsAndConditions/utils/getFaqLinks';

function isNavigatingFromPeYaCartOrCheckout({ consumer, originPath }) {
  if (!consumer) return false;
  if (!consumer.key || !isPeYaWeb(consumer.key)) return false;
  if (!consumer.origins || consumer.origins.length === 0) return false;

  return consumer.origins.some((o) => o.startsWith(originPath));
}

const Layout = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
`;

const CustomContainer = styled(Content)`
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  height: 30%;
  margin-bottom: ${getSpacing(6)};
`;

const TextInformation = styled(Typography)`
  margin-top: 5%;
  margin-bottom: ${getSpacing(12)};
`;

const PrivacyPolicies = styled(Typography)`
  display: flex;
  padding: ${getSpacing(0, 4, 4)};
  align-items: flex-end;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  > a {
    font-weight: 500;
    padding: 0 3px;
    display: inline-block;
    text-decoration: underline;
    color: rgb(16, 4, 35);
  }
`;

const AcceptInformation = styled(Typography)`
  margin-top: 5%;
  padding-bottom: ${getSpacing(4)};
`;

function SocialLogin({ intl: { formatMessage } }) {
  const [isLoading, setIsLoading] = useState(false);
  const [loginButtonsConfig, setLoginButtonsConfig] = useState(null);

  const router = useRouter();
  const { originPath, token } = router.query;

  const { socialNetworkName, socialNetworkValue } = getSocialNetworkFromParams(
    router.query,
  );
  const isSocialLoginSucceeded =
    !!socialNetworkName && socialNetworkValue === 'true';
  const isSocialLoginFailed =
    !!socialNetworkName && socialNetworkValue === 'false';

  const consumer = useConsumer();
  const countryShortName = useCountryShortName();

  const faqLinks = getFaqLinks({ consumer, countryShortName });

  const {
    mutateAsync: phoneTokenLoginMutateAsync,
    isLoading: isLoadingPhoneTokenLogin,
  } = usePhoneTokenLogin();
  const { mutateAsync: phoneValidationExternalMutateAsync } =
    usePhoneValidationExternal();
  const { mutateAsync: loginMethodsConfig } = useLoginMethods();

  const toast = useToast();

  const trackOrigin = isPeYaWeb(consumer.key)
    ? originPath || ORIGIN.HOME
    : consumer.name || ORIGIN.HOME;

  const trackClickAndRedirectTo = () => {
    trackLoginSignUpClicked({
      clickLocation: LOCATION.OMIT,
      origin: trackOrigin,
    });
    router.back();
  };

  const handleSocialFailure = () => {
    const { ec } = router.query;
    const errorMessage = `${formatMessage(
      messages[ec] ?? messages.socialNetworkGenericErrorMessage,
      { socialNetworkName: capitalize(socialNetworkName) },
    )}`;
    trackSocialLoginFailure({ errorMessage, type: socialNetworkName });
    toast.error(errorMessage);

    if (ec) delete router.query.ec;
    const newQuery = removeSocialNetworkParams(router.query);
    router.replace({ pathname: router.pathname, query: { ...newQuery } });
  };

  const clearSesssionStorage = () => {
    sessionStorage.removeItem(EMAIL);
    sessionStorage.removeItem(PHONE_TOKEN);
  };

  useEffect(() => {
    if (!socialNetworkName && !token) {
      trackLoginSignUpLoaded({
        loadingType: originPath
          ? LOADED_TYPE.AUTOMATICALLY
          : LOADED_TYPE.MANUALLY,
        layout: '',
        origin:
          trackOrigin === ORIGIN.HOME
            ? `${ORIGIN.HOME}-microsite`
            : trackOrigin,
      });
    }

    if (isSocialLoginSucceeded) {
      const { userId, isNewUser, redirectUrl } = router.query;

      const validRedirectUrls = Object.values(consumer.domains);
      const isValidRedirect = validateUrl(redirectUrl, validRedirectUrls);

      if (userId && isValidRedirect) {
        trackSocialLoginOk({
          userId,
          isNewUser: isNewUser === 'true',
          socialNetwork: socialNetworkName,
        });

        openPage(decodeURIComponent(redirectUrl));
      } else {
        handleSocialFailure();
      }
    }

    if (isSocialLoginFailed) {
      handleSocialFailure();
    }

    clearSesssionStorage();

    if (token) {
      phoneTokenValidator({ token });
    }
  }, []);

  const isCourier = isCourierConsumer(consumer?.name);
  const loginConfigurationFlag = FLAGS.LOGIN_CONFIGURATION_COURIER.KEY;
  const courierButtonsFlag = useFlagVariation(loginConfigurationFlag, false);

  useEffect(() => {
    async function getLoginMethods() {
      if (isCourier) {
        const courierButtonsConfig = mapCourierConfig(courierButtonsFlag);
        setLoginButtonsConfig(courierButtonsConfig);
        return;
      }

      const { data, success } = await loginMethodsConfig();
      if (success) {
        setLoginButtonsConfig(data);
      } else {
        setLoginButtonsConfig(DEFAULT_CONFIGURATION);
      }
    }
    getLoginMethods();
  }, [loginMethodsConfig]);

  // eslint-disable-next-line no-shadow
  const phoneTokenValidator = async ({ token }) => {
    const { success, data, error } = await phoneTokenLoginMutateAsync({
      phoneToken: token,
      loginFrom: consumer.key,
      originPath,
    });

    delete router.query.token;
    if (error) {
      const { networkStatus, code } = error;
      if (networkStatus === NON_ASSOCIATED_USER_CODE) {
        /**
         * We use this thing in order to hide phoneToken param but it's still in the router.
         * It will be obtainable by router.query.phoneToken
         */
        router.replace(
          // url
          {
            pathname: ROUTES.REGISTER_WITH_PHONE,
            query: { phoneToken: token, ...router.query },
          },
          // real url showed
          {
            pathname: ROUTES.REGISTER_WITH_PHONE,
            query: { ...router.query },
          },
        );
      } else {
        trackLoginFailed({
          errorMessage: code,
          type: TYPE.PHONE,
        });
        toast.error(`${formatMessage(messages.phoneLoginErrorMessage)}`);
      }
    }

    if (success) {
      const { redirectUrl, userId } = data;
      if (userId && redirectUrl) {
        setIsLoading(true);
        trackLoginSucceeded({
          type: TYPE.PHONE,
          userId,
        });
        openPage(redirectUrl);
      } else {
        router.replace({
          pathname: router.pathname,
          query: {
            ...router.query,
          },
        });
      }
    }
  };

  const redirectToPhoneValidation = async () => {
    setIsLoading(true);
    const { success, data } = await phoneValidationExternalMutateAsync();

    if (success) {
      const { phoneValidationUrl } = data;
      if (phoneValidationUrl) {
        delete router.query.loginType;

        const redirectTo = generateURL({
          pathname: window.location.origin + window.location.pathname,
          search: queryString.stringify(router.query),
        });

        const origin = `IRL_${originPath || ORIGIN.HOME}`;
        const url = generateURL({
          pathname: phoneValidationUrl,
          search: queryString.stringify({ redirectTo, origin }),
        });
        redirectToExternalUrl(url);
      }
    } else {
      setIsLoading(false);
      toast.error('No se pudo continuar con celular');
    }
  };

  const handleLoginMethodClick = (method) => {
    switch (method) {
      case 'phone':
        redirectToPhoneValidation();
        break;
      default:
        setIsLoading(true);
        break;
    }
  };

  return (
    <Layout id="irl__layout">
      {(isLoading ||
        isLoadingPhoneTokenLogin ||
        isSocialLoginSucceeded ||
        !loginButtonsConfig) && <OverlayLoader />}
      {loginButtonsConfig && (
        <Container background="red">
          <Header onClick={trackClickAndRedirectTo} />
          <CustomContainer>
            <Logo />
          </CustomContainer>
          <Footer>
            <TextInformation
              color="text-color-primary"
              token="font-headline-highcontrast-small"
            >
              {isNavigatingFromPeYaCartOrCheckout({ consumer, originPath })
                ? formatMessage(messages.titleForPeYaCartOrCheckout)
                : formatMessage(messages.titleForLogin)}
            </TextInformation>
            <LoginMethods
              origin={LOGIN_PAGE_TYPE.SOCIAL_LOGIN}
              trackOrigin={trackOrigin}
              onLoginMethodClick={handleLoginMethodClick}
              loginButtonsConfig={loginButtonsConfig}
            />
            <PrivacyPolicies
              color="text-color-primary"
              token="font-headline-highcontrast-small"
            >
              {formatMessage(messages.termsAndConditionsDisclaimer)}
              <Link href={{ pathname: faqLinks.tyc }} target="_blank">
                {formatMessage(messages.termsAndConditionsLink)}
              </Link>{' '}
              y{' '}
              <Link href={{ pathname: faqLinks.privacy }} target="_blank">
                {formatMessage(messages.privacyPoliciesLink)}
              </Link>
            </PrivacyPolicies>
            {showCountryDisclaimer(countryShortName) && (
              <AcceptInformation
                color="text-color-action-enabled"
                token="font-label-midcontrast-small"
              >
                {formatMessage(messages.acceptInformation)}
              </AcceptInformation>
            )}
          </Footer>
        </Container>
      )}
    </Layout>
  );
}

export default injectIntl(withRouter(SocialLogin));
