/* eslint-disable no-useless-escape */
import isString from './isString';

export default function removeSpecialChars(str) {
  return str
    .replace(/[àáâãäå]/gi, 'a')
    .replace(/[èéêë]/gi, 'e')
    .replace(/[ìíîï]/gi, 'i')
    .replace(/[òóôõö]/gi, 'o')
    .replace(/[ùúûü]/gi, 'u')
    .replace(/[ñ]/gi, 'n')
    .replace(/[ç]/gi, 'c')
    .replace(/[\'`´\"]/gi, ' ')
    .replace(/[\(\)]/gi, '');
}

export function format(str, ...args) {
  return str.replace(/{(\d+)}/g, (match, number) =>
    typeof args[number] !== 'undefined' ? args[number] : match,
  );
}
export function removeCharsExceptLetters(str) {
  const string = removeSpecialChars(str);
  return string.replace(/[\(\)]/gi, '').replace(/[^a-zA-Z ]/g, '');
}

export function capitalize(str) {
  if (str.length <= 1) return str.toUpperCase();
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );
}

export function joinAsSentence(stringArray) {
  return stringArray.join(', ').replace(/,(?!.*,)/gim, ' y');
}

export function isEmpty(value) {
  const valueTrimed = value && isString(value) && value.trim();
  return !valueTrimed;
}
