const LOGIN_ERROR_CODES = {
  USR_INVALID_CREDENTIALS: 'USR_INVALID_CREDENTIALS',
  INVALID_DOMAIN: 'INVALID_DOMAIN',
  NOT_ALLOWED: 'NOT_ALLOWED',
  NON_ASSOCIATED_USR: 'NON_ASSOCIATED_USR',
  USR_FACEBOOK: 'USR_FACEBOOK',
  FACEBOOK_LOGIN: 'FACEBOOK_LOGIN',
  GOOGLE_LOGIN: 'GOOGLE_LOGIN',
  USR_FACEBOOK_NO_MAIL: 'USR_FACEBOOK_NO_MAIL',
  QUOTA_EXCEEDED: 'QUOTA_EXCEEDED',
  INVALID_PHONE_TOKEN: 'INVALID_PHONE_TOKEN',
  INVALID_OTP_CODE: 'INVALID_OTP_CODE',
  SOCIAL_LOGIN_FORBIDDEN: 'SOCIAL_LOGIN_FORBIDDEN',
};

const OTP_ERROR_CODES = {
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  INVALID_OTP_CODE: 'INVALID_OTP_CODE',
};

const SIGN_UP_ERROR_CODES = {
  PHONE_REGISTER_DENIED: 'PHONE_REGISTER_DENIED',
  INVALID_DOMAIN: 'INVALID_DOMAIN',
};

const LOGIN_ALLOWED_ERROR_CODES = [
  LOGIN_ERROR_CODES.USR_INVALID_CREDENTIALS,
  LOGIN_ERROR_CODES.INVALID_DOMAIN,
  LOGIN_ERROR_CODES.NOT_ALLOWED,
  LOGIN_ERROR_CODES.NON_ASSOCIATED_USR,
  LOGIN_ERROR_CODES.USR_FACEBOOK_NO_MAIL,
  LOGIN_ERROR_CODES.INVALID_PHONE_TOKEN,
  LOGIN_ERROR_CODES.SOCIAL_LOGIN_FORBIDDEN,
];

module.exports = {
  LOGIN_ERROR_CODES,
  OTP_ERROR_CODES,
  SIGN_UP_ERROR_CODES,
  LOGIN_ALLOWED_ERROR_CODES,
};
