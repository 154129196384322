import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useRouter } from 'next/router';

/**
 * Tracking
 */
import trackAuthMethodButtonClick, {
  METHOD,
} from '@/external-libs/tracker/events/trackAuthMethodButtonClick';

/**
 * Providers
 */
import { useConsumer } from '@/src/providers/ConsumerProvider';

/**
 * Services
 */
import {
  getRedirectToFailure,
  getRedirectToSuccess,
  redirectToSocialNetwork,
} from '@/src/modules/user/router/service';

/**
 * Constants
 */
import { FB_LOGIN_FAILURE_QUERY, QUERY_PARAM } from '@/src/constants';
import ROUTES from '@/src/constants/routes';
import {
  LOGIN_PAGE_TYPE,
  SIGN_UP_TYPE,
  FACEBOOK_LOGIN_REDIRECT,
  SOCIAL_NETWORK,
} from '../../components/SocialLogin/constants';

/**
 * Context
 */
import { usePrivacyPoliciesAndTermsAndConditions } from '../contexts/PrivacyPoliciesAndTermsAndConditionsContext';

/**
 * Components
 */
import PhoneButton from '../../components/SocialLogin/PhoneButton';
import GoogleSignInButton from '../../components/SocialLogin/GoogleSignInButton';
import FacebookButton from '../../components/SocialLogin/FacebookButton';
import EmailButton from '../../components/SocialLogin/EmailButton';
import OtpEmail from '../../components/SocialLogin/OTP';

const noop = () => {};

const { FACEBOOK, GOOGLE } = SOCIAL_NETWORK;
const { PHONE, PASSWORD, EMAIL, OTP_EMAIL, OTP } = SIGN_UP_TYPE;

function LoginButtonGroup({
  buttonsConfiguration,
  cameFromSideMenu,
  origin,
  trackData,
  isBottomSheetButton,
  onLoginMethodClick = noop,
  closeBottomSheet = noop,
}) {
  const router = useRouter();
  const { originPath, loginType } = router.query;

  const consumer = useConsumer();

  const locationNext = {
    search: router.query,
    pathname: router.pathname,
  };

  const { show: showPrivacyPoliciesAndTermsAndConditions } =
    usePrivacyPoliciesAndTermsAndConditions();

  const goBack = () => router.back();

  useEffect(() => {
    switch (loginType) {
      case FACEBOOK:
        showPrivacyPoliciesAndTermsAndConditions(
          handleFacebookButtonPress,
          goBack,
        );
        break;
      case GOOGLE:
        // handleGoogleButtonPress();
        break;
      case PHONE:
        showPrivacyPoliciesAndTermsAndConditions(
          handlePhoneButtonPress,
          goBack,
        );
        break;
      case EMAIL:
        showPrivacyPoliciesAndTermsAndConditions(
          handleEmailButtonPress,
          goBack,
        );
        break;
      case OTP_EMAIL:
        showPrivacyPoliciesAndTermsAndConditions(
          handleOtpEmailButtonPress,
          goBack,
        );
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createNetworkRedirectToSuccess = (redirectSuccessQuery) =>
    getRedirectToSuccess({
      shouldRedirect: cameFromSideMenu,
      location: locationNext,
      redirectSuccessQuery,
    });

  const createNetworkRedirectToFailure = (redirectFailureQuery) =>
    getRedirectToFailure({
      shouldRedirect: cameFromSideMenu,
      location: locationNext,
      redirectFailureQuery,
    });

  const handleTrackAuthMethodButtonClick = (method) =>
    trackAuthMethodButtonClick({
      method,
      irlConfiguration: trackData.irlConfiguration,
      origin: trackData.origin,
    });

  const handleNetworkButtonPress = ({
    onSuccessRedirectTo,
    onFailureRedirectTo,
    socialQueryRedirect,
    socialNetwork,
  }) => {
    closeBottomSheet();
    handleTrackAuthMethodButtonClick(socialNetwork);
    onLoginMethodClick(socialNetwork);
    redirectToSocialNetwork({
      onSuccessRedirectTo,
      onFailureRedirectTo,
      socialQueryRedirect,
      origin,
      loginFrom: consumer.key,
      originPath,
    });
  };

  const handleGoogleButtonPress = () => {
    closeBottomSheet();
    handleTrackAuthMethodButtonClick(SOCIAL_NETWORK.GOOGLE);
    onLoginMethodClick('google');
  };

  const handleFacebookButtonPress = () =>
    handleNetworkButtonPress({
      onSuccessRedirectTo: createNetworkRedirectToSuccess(QUERY_PARAM.FB_LOGIN),
      onFailureRedirectTo: createNetworkRedirectToFailure(
        FB_LOGIN_FAILURE_QUERY,
      ),
      socialQueryRedirect: FACEBOOK_LOGIN_REDIRECT,
      socialNetwork: SOCIAL_NETWORK.FACEBOOK,
    });

  const handleEmailButtonPress = () => {
    handleTrackAuthMethodButtonClick(METHOD.MAIL);
    onLoginMethodClick('email');
    delete router.query.loginType;
    router.push({
      pathname: ROUTES.LOGIN_WITH_EMAIL,
      query: { ...router.query },
    });
  };

  const handleOtpEmailButtonPress = () => {
    handleTrackAuthMethodButtonClick(METHOD.OTP);
    onLoginMethodClick('email');
    delete router.query.loginType;
    router.push({
      pathname: ROUTES.LOGIN_WITH_OTP_EMAIL,
      query: { ...router.query },
    });
  };

  const handlePhoneButtonPress = () => {
    handleTrackAuthMethodButtonClick(METHOD.PHONE);
    onLoginMethodClick('phone');
  };

  return (
    <>
      {buttonsConfiguration.map(({ method }) => {
        const normalizedMethod = method.toLowerCase();

        switch (normalizedMethod) {
          case FACEBOOK:
            return (
              <FacebookButton
                key={method}
                onClick={handleFacebookButtonPress}
                isBottomSheetButton={isBottomSheetButton}
              />
            );
          case GOOGLE:
            return (
              <GoogleSignInButton
                key={method}
                loginFrom={consumer.key}
                originPath={originPath}
                onClick={handleGoogleButtonPress}
              />
            );
          case PASSWORD:
            return (
              <EmailButton
                key={method}
                onClick={handleEmailButtonPress}
                isBottomSheetButton={isBottomSheetButton}
                closeBottomSheet={closeBottomSheet}
              />
            );
          case PHONE:
            return (
              <PhoneButton
                key={method}
                onClick={handlePhoneButtonPress}
                isBottomSheetButton={isBottomSheetButton}
                closeBottomSheet={closeBottomSheet}
              />
            );
          case OTP_EMAIL:
          case OTP:
            return (
              <OtpEmail
                key={method}
                onClick={handleOtpEmailButtonPress}
                isBottomSheetButton={isBottomSheetButton}
                closeBottomSheet={closeBottomSheet}
              />
            );
          default:
            return null;
        }
      })}
    </>
  );
}

LoginButtonGroup.defaultProps = {
  isBottomSheetButton: false,
};

LoginButtonGroup.propTypes = {
  buttonsConfiguration: PropTypes.arrayOf(
    PropTypes.shape({
      method: PropTypes.oneOf([
        FACEBOOK,
        GOOGLE,
        PASSWORD,
        PHONE,
        OTP,
        OTP_EMAIL,
      ]).isRequired,
      priority: PropTypes.number.isRequired,
    }),
  ).isRequired,
  cameFromSideMenu: PropTypes.bool.isRequired,
  onLoginMethodClick: PropTypes.func.isRequired,
  origin: PropTypes.oneOf(Object.values(LOGIN_PAGE_TYPE)).isRequired,
  trackData: PropTypes.object.isRequired,
  closeBottomSheet: PropTypes.func,
  isBottomSheetButton: PropTypes.bool,
};

export default withRouter(LoginButtonGroup);
