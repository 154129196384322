import { track } from '../index';
import { LOGIN_SUCCEEDED, EMAIL, PHONE } from '../constants';

export const TYPE = {
  EMAIL: EMAIL,
  PHONE: PHONE,
};

function trackLoginSucceeded({ type, userId }) {
  const EVENT_NAME = LOGIN_SUCCEEDED;

  track(EVENT_NAME, {
    loginType: type,
    userId,
  });
}

export default trackLoginSucceeded;
