import styled, { css } from 'styled-components';
import { getSpacing } from '@/src/utils/styles/fenixHelpers';

const Footer = styled.footer`
  align-items: center;
  display: flex;
  height: 60%;
  background-color: white;
  flex-direction: column;
  color: ${({ theme }) => theme.color('text-color-action-enabled')};
  padding: ${getSpacing(10, 8)};
  text-align: center;
  transition: 0.25s;
  transition-timing-function: ease-out;
  opacity: 1;
  ${({ hideFooter }) =>
    hideFooter &&
    css`
      opacity: 0;
      transform: translateY(130%);
      transition-timing-function: ease-in;
      transition: 0.2s;
    `}
`;

export default Footer;
