function openInSameTab(page) {
  window.location.replace(page);
}

function openInNewTab(page) {
  const newTab = window.open(page, '_blank');
  newTab.focus();
}

function openPage(page, newTab = false) {
  if (newTab) openInNewTab(page);
  else openInSameTab(page);
}

export default openPage;
