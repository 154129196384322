import { useMutation } from 'react-query';

/**
 * Services
 */
import loginWithPhoneToken from '@/src/services/auth/loginWithPhoneToken';

export function usePhoneTokenLogin() {
  return useMutation(loginWithPhoneToken);
}
