import getInstance from '@/external-libs/axios';

/**
 * Utils
 */
import { phoneValidationURL } from '@/src/constants/urls/apiURLs';
import ServiceResponse from '@/src/models/ServiceResponse';

async function getPhoneValidationUrlExternal(loginFrom) {
  try {
    const url = loginFrom
      ? `${phoneValidationURL}?loginFrom=${loginFrom}`
      : phoneValidationURL;
    const axios = getInstance();
    const { data } = await axios.get(url);

    return new ServiceResponse({ data });
  } catch (error) {
    return new ServiceResponse({ error });
  }
}

export default getPhoneValidationUrlExternal;
