import React from 'react';

/**
 * Components
 */
import SocialLogin from '@/src/modules/Login/components/SocialLogin';
import { PrivacyPoliciesAndTermsAndConditionsProvider } from '@/src/modules/Login/containers/contexts/PrivacyPoliciesAndTermsAndConditionsContext';

function LoginFlow() {
  return (
    <PrivacyPoliciesAndTermsAndConditionsProvider>
      <SocialLogin />
    </PrivacyPoliciesAndTermsAndConditionsProvider>
  );
}

export default LoginFlow;
