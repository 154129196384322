import { defineMessages } from 'react-intl';

export default defineMessages({
  text: {
    id: 'app.modules.login.emailPasswordButton.text',
    defaultMessage: 'Continuar con correo electrónico y contraseña',
  },
  buttonText: {
    id: 'app.modules.login.emailPasswordButton.buttonText',
    defaultMessage: 'Continuar con email y contraseña',
  },
});
