import styled, { css } from 'styled-components';

/**
 * Components
 */
import Button from '@pedidosya/web-fenix/atoms/Button';

export const SocialButton = styled(Button).attrs((props) => ({
  label: props.label,
  size: 'large',
  hierarchy: 'secondary',
  fullWidth: true,
}))`
  height: 40px;
  border-color: ${({ theme }) =>
    theme.color('shape-color-stroke-action-enabled-default')};
  > div span {
    font-weight: 500;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: auto;
      opacity: 0.4;
      pointer-events: none;
    `};
`;
