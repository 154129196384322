import { defineMessages } from 'react-intl';

export default defineMessages({
  text: {
    id: 'app.user.containers.socialLogin.phoneButton.text',
    defaultMessage: 'Continuar con celular',
  },
  buttonText: {
    id: 'app.user.containers.socialLogin.phoneButton.buttonText',
    defaultMessage: 'Continuar con celular',
  },
});
