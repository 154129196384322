import { defineMessages } from 'react-intl';

export default defineMessages({
  accept: {
    id: 'app.modules.user.components.termsAndConditions.accept',
    defaultMessage: 'Aceptar',
  },
  privacyPolicies: {
    id: 'app.modules.user.components.termsAndConditions.privacyPolicy',
    defaultMessage: 'políticas de privacidad',
  },
  termsAndConditions: {
    id: 'app.modules.user.components.termsAndConditions.termsAndConditions',
    defaultMessage: 'términos de uso',
  },
  text: {
    id: 'app.modules.user.components.termsAndConditions.text',
    defaultMessage:
      'Para continuar te pedimos que aceptes nuestros {termsAndConditions} y {privacyPolicies}.',
  },
  countryDisclaimer: {
    id: 'app.modules.user.components.termsAndConditions.countryDisclaimer',
    defaultMessage:
      'Al aceptar también confirmas que darás tu nombre completo como lo indica tu documento de identificación oficial.',
  },
});
