import { track } from '../index';
import { EMAIL, PHONE, LOGIN_FAILED } from '../constants';

export const TYPE = {
  EMAIL: EMAIL,
  PHONE: PHONE,
};

function trackLoginFailed({ errorMessage, type }) {
  const EVENT_NAME = LOGIN_FAILED;

  track(EVENT_NAME, {
    loginErrorMessage: errorMessage || 'Internal server error',
    loginType: type,
  });
}

export default trackLoginFailed;
