import { QUERY_PARAM } from '@/src/constants';
import { SOCIAL_NETWORK } from '@/external-libs/tracker/constants';

const getSocialNetworkFromParams = (search) => {
  if (search && search[QUERY_PARAM.FB_LOGIN]) {
    return {
      socialNetworkName: SOCIAL_NETWORK.FACEBOOK,
      socialNetworkValue: search[QUERY_PARAM.FB_LOGIN],
    };
  }

  if (search && search[QUERY_PARAM.GOOGLE_LOGIN]) {
    return {
      socialNetworkName: SOCIAL_NETWORK.GOOGLE,
      socialNetworkValue: search[QUERY_PARAM.GOOGLE_LOGIN],
    };
  }

  return {
    socialNetworkName: null,
    socialNetworkValue: null,
  };
};

const removeSocialNetworkParams = (search) => {
  if (search && search[QUERY_PARAM.FB_LOGIN]) {
    // eslint-disable-next-line no-param-reassign
    delete search[QUERY_PARAM.FB_LOGIN];
  }

  if (search && search[QUERY_PARAM.GOOGLE_LOGIN]) {
    // eslint-disable-next-line no-param-reassign
    delete search[QUERY_PARAM.GOOGLE_LOGIN];
  }

  return search;
};

export { getSocialNetworkFromParams, removeSocialNetworkParams };
