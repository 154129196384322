import COUNTRIES from '@appscore/web-constants/countries';

const ROUTES = {
  PRIVACY_POLICIES: '/about/privacy_policies',
  TYC_GENERALS: '/about/tyc_generals',
  TYC_COURIER_BUSINESS: '/about/courier-business/tyc',
  PRIVACY_COURIER_BUSINESS: '/about/courier-business/privacy',
};

const defaultLinks = {
  tyc: ROUTES.TYC_GENERALS,
  privacy: ROUTES.PRIVACY_POLICIES,
};

function getFaqLinks({ consumer, countryShortName }) {
  try {
    const country = COUNTRIES.find((c) => c.shortName === countryShortName);
    const { live } = country;

    let links = {
      tyc: `${live}${defaultLinks.tyc}`,
      privacy: `${live}${defaultLinks.privacy}`,
    };

    if (consumer && consumer.key && consumer.termsAndConditionsConfig) {
      const { termsAndConditionsConfig } = consumer;
      links = {
        tyc: `${live}${termsAndConditionsConfig.TYC}`,
        privacy: `${live}${termsAndConditionsConfig.PRIVACY_POLICIES}`,
      };
    }

    return links;
  } catch (error) {
    console.error(
      'Error al obtener los enlaces de preguntas frecuentes:',
      error,
    );
    return null;
  }
}

export default getFaqLinks;
